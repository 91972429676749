import { isEmpty } from "lodash";
import React, { useState } from "react";
import { Content, Theme } from "../../../interfaces";
import { VideoCard } from "./VideoCard";
import * as Styled from "./VideoList.styled";
import Button from "../../Button";
import { padding } from "polished";

interface VideoListProps {
	clientKey?: string;
	channelKey?: string;
	onVideoEnd: () => void;
	relatedContents: Content[];
	upcomingContents: Content[];
	showRelatedTitle?: boolean;
	contentKey?: string;
	theme: Theme;
}

export const VideoList: React.FC<VideoListProps> = ({
	clientKey,
	channelKey,
	contentKey,
	onVideoEnd,
	relatedContents,
	upcomingContents,
	showRelatedTitle = true,
	theme,
}) => {
	const [showMore, setShowMore] = useState(false);

	const hasRelated = !isEmpty(relatedContents);

	return (
		<>
			{!isEmpty(upcomingContents) && (
				<Styled.Section>
					<h2>Upcoming</h2>
					<div className={`card-list`}>
						{upcomingContents.map((content: Content, index) =>
							index < 3 || !hasRelated || showMore ? (
								<VideoCard
									key={content.id}
									video={content}
									clientKey={clientKey}
									channelKey={channelKey}
									onVideoEnd={onVideoEnd}
									contentKey={contentKey}
								/>
							) : null
						)}
					</div>
					{!showMore && hasRelated && (
						<Button
							backgroundColor={theme.buttonBackgroundColor}
							color={theme.buttonTextColor}
							hoverBackgroundColor={theme.buttonHoverBackgroundColor}
							hoverColor={theme.buttonTextColor}
							onClick={() => {
								setShowMore(true);
							}}
							style={{ padding: 10, textAlign: "center" }}
						>
							See More
						</Button>
					)}
				</Styled.Section>
			)}

			{hasRelated && (
				<Styled.Section>
					{showRelatedTitle && <h2>On Demand</h2>}
					<div className={`card-list`}>
						{relatedContents.map((content: Content) => (
							<VideoCard
								key={content.id}
								video={content}
								clientKey={clientKey}
								channelKey={channelKey}
								onVideoEnd={onVideoEnd}
								contentKey={contentKey}
							/>
						))}
					</div>
				</Styled.Section>
			)}
			{isEmpty(upcomingContents) && isEmpty(relatedContents) && !contentKey && (
				<Styled.Section className="no-results" style={{ textAlign: "center" }}>
					<i className="fal fa-tv-retro fa-3x m-bottom" />
					<h2>This channel contains no content</h2>
				</Styled.Section>
			)}
		</>
	);
};
